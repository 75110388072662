"use client"

import {useEffect} from "react";

export default function AffiliateTracking() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');

    if (ref) {
      document.cookie = `ref=${ref}; path=/; max-age=31536000; SameSite=Srict`;
    }
  });

  return (
    <></>
  )
}
